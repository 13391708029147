@font-face {
  font-family: American Signature;
  src: url('/public/assets/fonts/american-signature.ttf');
}

@font-face {
  font-family: Poppins;
  src: url('/public/assets/fonts/poppins-regular.ttf');
}

.App {
  display: block;
  width: 100%;
  height: 100%;
  /* padding: 2em 4em; */
}

.landing-page {
  /* display: flex;
  flex-direction: column;
  justify-content: center; */
  overflow: hidden;
  height: 99.9vh
}

.brand-name {
  margin-left: 40px;
  text-align: center;
  transform: rotate(-15.29deg);
  font-family: "American Signature";
  font-size: 15em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 49.92px;
  background: linear-gradient(176deg, #FFC262 23.04%, #D49126 73.6%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  height: 40%;
}

.tagline {
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 3.6px;
  background: linear-gradient(176deg, #FFC262 23.04%, #D49126 73.6%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.page-1 {}

.page-1 img {
  width: 100%
}

.golden-border {
  width: 100%;
  height: 6px;
  flex-shrink: 0;
  background: var(--Tertiary-Gold_Brown, linear-gradient(90deg, #6C4908 0%, #FFC15D 50.63%, #834E00 100%));
}

.catch-phrase-container {
  padding: 2em 0;
  width: 100%;
  flex-shrink: 0;
  background-color: white;
}

.catch-phrase-container svg.quotes {
  display: block;
  height: 3em;
  margin: auto;
}

.catch-phrase {
  color: var(--Secondary-Black, #0E0E0E);
  text-align: center;
  font-family: Poppins;
  font-size: 1.5em;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 2.1px;
  max-width: 600px;
  margin: auto;
  padding: 2em 1em;
}

.golden-bar {
  display: block;
  margin: auto;
  margin-bottom: 2em;
}

.page-2 {
  padding-top: 2em;
  padding-bottom: 2em;
  background-color: white;
}

.page-2 h3 {
  text-align: center;
  color: #2D40A4;
  font-size: 1.7em;
  margin: 0;
  margin-bottom: 0.3em;
}

.page-2 .content-container {
  max-width: 600px;
  margin: auto;
}

.page-2 h4 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: #2D40A4;
  font-family: Poppins;
  font-size: 1.2em;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}

.page-2 p {
  color: #515D98;
  font-family: Poppins;
  font-size: 1em;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0em 0.5em;
}

.page-3 {
  text-align: center;
  padding-top: 2em;
  padding-bottom: 5em;
  background-color: #090D1A;
}

.page-3 h2 {
  margin-top: 0;
  color: #FFF;
  font-family: Poppins;
  font-size: 2.5em;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.page-3 h3 {
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: var(--Tertiary-Gold_Yellow, linear-gradient(90deg, #EFAA2F 0%, #FCC162 50.63%, #D18C27 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.page-3 address {
  color: var(--Tertiary-White_Green, #F6FFF3);
  text-align: center;
  font-family: Poppins;
  font-size: 1.2em;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 1.12px;
}

.page-3 .contact {
  color: var(--Tertiary-White_Green, #F6FFF3);
  text-align: center;
  font-family: Poppins;
  font-size: 1.2em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.12px;
  text-decoration: none;
}

@media screen and (max-width:700px) {
  .brand-name {
    font-size: 6em;
    letter-spacing: 0.1em;
  }

  .tagline {
    font-size: 12px;
    letter-spacing: 0.1em;
  }
}